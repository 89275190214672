import './App.css';
import Header from './Components/Header/Header';
import Container from 'react-bootstrap/Container';
import routes from './routes';
import Footer from './Components/Footer/Footer';
function App() {
  return (
    <div className="App">
        <Header />
        <main>
        <Container>
          {routes}
        </Container>
        <h3 className="text-center mt-5">We are located in Columbia, PA</h3>
        <h3 className="text-center mt-5"><a href="tel:(385) 200-4172">(385) 200-4172</a></h3>
        </main>
        <Footer/>
    </div>
  );
}

export default App;
