import React from "react";
import Container from "react-bootstrap/Container";

import PuppyList from "./PuppyList";
import { Link } from "react-router-dom";
function Puppies() {
  let potterPuppies = [
    {
      name: "Dobby (Reserved)",
      imageSm: "/images/fullsize/dobby.jpg",
      imageFull: "/images/fullsize/dobby.jpg",
      desc: `Dobby is a big boy! He is so playful and loves to run around. He has white, brown, and black hair. His hair will be a little more on the curlier side. He is reserved.`,

    },
    {
      name: "Ron",
      imageSm: "/images/fullsize/ron.jpg",
      imageFull: "/images/fullsize/ron.jpg",
      desc: `Ron is black and brown with a touch of white near his nose. He loves to be held and cuddle. He is a very fun little guy.`,
    },
    {
      name: "Hermoine (Reserved)",
      imageSm: "/images/fullsize/hermoine.jpg",
      imageFull: "/images/fullsize/hermoine.jpg",
      desc: "Hermoine has a very unique black and white coat. She is very sweet and loves to play. Her hair will likely be more on the wavy side.",
    },
    {
      name: "Luna Lovegood",
      imageSm: "/images/fullsize/luna.jpg",
      imageFull: "/images/fullsize/luna.jpg",
      desc: "Luna is our curly little princess. She is black with a white chin and chest. She is very sweet and loves to play. She is so adorable.",
    },
    {
      name: "Fred",
      imageSm: "/images/fullsize/fred.jpg",
      imageFull: "/images/fullsize/fred.jpg",
      desc: "Fred is tied for the smallest in the litter. He has all black hair and it is the straightest of the litter. It will end up wavy like his mom's hair. He is so calm and loves to explore. He plays with his siblings often and has the cutest little strut.",
    },

    {
      name: "George",
      imageSm: "/images/fullsize/george.jpg",
      imageFull: "/images/fullsize/george.jpg",
      desc: "George is such a fun and playful pup. He is mostly black with a white chin and chest. He will be quite the adventurous little guy. ",
    },
    {
      name: "Harry",
      imageSm: "/images/fullsize/harry.jpg",
      imageFull: "/images/fullsize/harry.jpg",
      desc: "Harry is the cuddliest of them all. He loves naps and trotting around the yard with his little legs. He is black with a white chin and chest. His hair will likely be more curly.",
    },
  ];
  let hobbitPuppies = [
    {
      name: "Bilbo (Marley)",
      imageSm: "/images/small/bilbo-min.jpg",
      imageFull: "/images/fullsize/bilbo.jpg",
      desc: `Bilbo is such a chunk! He is so sweet and loves to play with his ball. He has white on his head and chest and darker brown throughout. His hair will be a little more on the curlier side.`,
    },
    {
      name: "Frodo (Jasper)",
      imageSm: "/images/small/frodo-min.jpg",
      imageFull: "/images/fullsize/frodo.jpg",
      desc: `Frodo is a darker brown with hints of white on his chest and belly. He is sweet and mild-tempered. He loves to be held and to play.`,
    },
    {
      name: "Samwise (Milo)",
      imageSm: "/images/small/samwise-min.jpg",
      imageFull: "/images/fullsize/samwise.jpg",
      desc: "Samwise is the most colorful of our pups. He loves to play and to take long naps. He is smart and loves to give kisses!",
    },
    {
      name: "Merry (Maggie)",
      imageSm: "/images/small/merry-min.jpg",
      imageFull: "/images/fullsize/merry.jpg",
      desc: "Merry is the smallest in the litter and so adorable. She looks just like her mom and is the sweetest girl. She loves to be held and give kisses.",
    },

    {
      name: "Pippin (Bailey)",
      imageSm: "/images/small/pippin-min.jpg",
      imageFull: "/images/fullsize/pippin.jpg",
      desc: "Pippin is the biggest puppy in the litter, but he is so sweet. He loves to eat and chase balls. He also loves to run around. He will also look like his mom!",
    },
  ];
  let puppies = hobbitPuppies;
  return (
    <Container>
      <h2 className="text-center">Honey's Potter Puppy Pals</h2>
      <h5 className="text-center mt-4">They were born on September 7th, 2023.They will be ready to go home the first week of November. We are now accepting reservations.</h5>
      <PuppyList puppies={potterPuppies} />
      <h5 className="text-center my-5">
        <Link style={{ color: "#7CB2BE" }} to="/parents">
          Their parents are Honey and Scout
        </Link>
      </h5>
      <hr />
      <h3 className='text-center '>Past Litter</h3>
      <hr />
      <h2 className="text-center">Honey's Hobbit Litter</h2>
      <h5 className="text-center mt-4">They were born on August 1st, 2022. These puppies are all in their new "furever" homes.</h5>
      <PuppyList puppies={hobbitPuppies} />
      <h5 className="text-center my-5">
        <Link style={{ color: "#7CB2BE" }} to="/parents">
          Their parents are Honey and Louis
        </Link>
      </h5>
      {/* <h3 style={{ color: "#7CB2BE" }} className=" my-5 text-center roboto">
       <em>Puppy prices start at $1800.</em> 
      </h3> */}
      {/* <h4 className="text-center">We do not have a waitlist at this time. <u>There are no plans for Mini Goldendoodles in the near future.</u></h4> */}
      {/* <h3 className=" my-5 text-center roboto">
       Puppy prices start at $1800. $500 non-refundable deposit due at the time of reservation of a specific puppy. 
      </h3> */}
      {/* <h2>Call or text Dallin at (385) 200-4172 to inquire about puppy
        reservations and visits.</h2> */}
    </Container>
  );
}

export default Puppies;
